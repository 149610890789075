import * as React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as styles from '../styles/article.module.css'

interface Mdx {
	readonly title: string
	readonly author: string
	readonly date: string
	readonly body: string
}

interface PostContentSectionProps {
	readonly children: Mdx
}

const PostContentSection = ({ children }: PostContentSectionProps) => (
	<div className='w-full bg-gray-800 flex flex-col text-gray-50'>
		<article className={styles.articleContainer}>
			<h1>{children.title}</h1>
			<p>
				Author:{' '}
				<span className='text-yellow-400 text-lg font-semibold inline'>
					{children.author}
				</span>
			</p>
			<p>
				Posted: <span className='text-gray-400'>{children.date}</span>
			</p>
			<hr />
			<MDXRenderer>{children.body}</MDXRenderer>
		</article>
	</div>
)

export default PostContentSection
