import * as React from 'react'
import { graphql } from 'gatsby'
import { flow } from 'fp-ts/function'
import * as Array from 'fp-ts/Array'
import { getFeaturedSheets, PageSheet } from '../../repository/sheet.repository'
import { constructNavigatableItem } from '../../common/navigatable'
import { getSiteData } from '../../repository/site.repository'
import Layout from '../../components/layout'
import HeroHeader from '../../components/hero-header'
import PostContentSection from '../../components/post-content-section'
import Footer from '../../components/footer'
import { Helmet } from 'react-helmet'

type QueryResult = {
	mdx: {
		frontmatter: {
			img: string
			title: string
			author: string
			date: string
		}
		body: string
	}
}

interface PostDetailPageProps {
	readonly data: QueryResult
}

const PostDetailPage = ({ data }: PostDetailPageProps) => {
	const siteData = getSiteData()
	const sheets = getFeaturedSheets()
	return (
		<Layout
			img={data.mdx.frontmatter.img}
			siteUrl={siteData.url}
			title={siteData.title}
			subtitle={siteData.subtitle}
			navigationItems={extractNavItems(sheets)}
		>
			<HeroHeader
				title={data.mdx.frontmatter.title}
				subtitle=''
				description=''
			/>
			<PostContentSection>
				{{
					title: data.mdx.frontmatter.title,
					author: data.mdx.frontmatter.author,
					date: data.mdx.frontmatter.date,
					body: data.mdx.body
				}}
			</PostContentSection>
			<Footer />
			<Helmet>
				<title>
					{data.mdx.frontmatter.title} - {siteData.title}
				</title>
			</Helmet>
		</Layout>
	)
}

const extractNavItems = flow(
	Array.map((sheet: PageSheet) => ({
		id: sheet.id,
		label: sheet.label,
		url: `/sheet/${sheet.slug}`
	})),
	Array.map(constructNavigatableItem),
	Array.prepend({ id: 'home', label: 'Home', url: '/' })
)

export const query = graphql`
	query ($id: String) {
		mdx(id: { eq: $id }) {
			frontmatter {
				img
				title
				author
				date
			}
			body
		}
	}
`

export default PostDetailPage
